import ANCHOR from "../images/token_icons/ANCHOR-Token.svg";
import BTC from "../images/token_icons/BTCB-Token.svg";
import BNB from "../images/token_icons/WBNB-Token-Icon.svg";
import ETH from "../images/token_icons/ETH-Token.svg";

import defaultImg from "../images/token_icons/default.svg";
// import USDT from "../images/token_icons/USDT.svg";
import routerABI from "../ABI/router.ABI.json";
import VozIcon from "../../assets/images/voz.svg";
import factoryABI from "../ABI/factory.ABI.json";
import pairABI from "../ABI/pair.ABI.json";
export const BURN_ADDRESS = "0x000000000000000000000000000000000000dEaD";
export const DEFLATIONNARY_TOKENS = [
  "0x5bb3e78e33cf28eC1bBa34355fe84BE3d397ee33", //saita
];
export const LIQUIDITY_PROVIDER_FEE = 0.2;

// ******** BSC_MAIN_NET LP's, WETH and USD **********
// export const ANCHOR_BUSD_LP = "0xca8cb77efac26f901042196f766bac4ee5077df0";
// export const BNB_BUSD_LP = "0xe2466652a46e47fa278be0a2ad8dce7c8445be41";
// export const WETH = "0xc778417E063141139Fce010982780140Aa0cD5Ab";
// export const USD = "0xe9e7cea3dedca5984780bafc599bd69add087d56";
export const pancakeFactory = "0xa4C8a3e0b49F966F1B241Ca350C5121230988310";

// ******** BSC_TEST_NET LP's, WETH and USD **********
export const ANCHOR_BUSD_LP = "0xE49d2CE0157D43941F05E969793aAFa71D90BaA4";
export const BNB_BUSD_LP = "0xE5799D95Ac7ECCbAf672777eDb516D29c030f5a9";
export const WETH = "0x58F6b59eBF696De4f65b4e61d3DB5FEB1376DDb3"; //mainNet weth
export const USD = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
export const Saitama = "0x5bb3e78e33cf28eC1bBa34355fe84BE3d397ee33"; //mainnet Saita

// ******** BSC_MAIN_NET Token List **********
export const TOKEN_LIST = [
  // {
  //   icon: USDT,
  //   name: "ether token",
  //   address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
  //   isAdd: true,
  //   isDel: false,
  //   decimals: 18,
  //   symbol: "ETH",
  // },
  // {
  //   icon: USDT,
  //   name: "saitama token",
  //   address: "0x4aac18De824eC1b553dbf342829834E4FF3F7a9F",
  //   isAdd: true,
  //   isDel: false,
  //   decimals: 18,
  //   symbol: "SAITAMA",
  // },
  {
    icon: ETH,
    name: "ETH",
    address: "BNB",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "ETH",
  },
  {
    icon: VozIcon,
    name: "VOZ",
    address: "0x5bb3e78e33cf28eC1bBa34355fe84BE3d397ee33",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "VOZ",
  }
  // {
  //   icon: USDT,
  //   name: "wrapped ether token",
  //   address: "0x5ac5e6Af46Ef285B3536833E65D245c49b608d9b",
  //   isAdd: true,
  //   isDel: false,
  //   decimals: 18,
  //   symbol: "WETH",
  // },
  // {
  //   icon: BUSD,
  //   name: "BUSD",
  //   address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
  //   isAdd: false,
  //   isDel: false,
  //   decimals: 18,
  //   symbol: "BUSD",
  // },
  // {
  //   icon: ANCHOR,
  //   name: "ANCHOR",
  //   address: "0x4aac18De824eC1b553dbf342829834E4FF3F7a9F",
  //   isAdd: false,
  //   isDel: false,
  //   decimals: 18,
  //   symbol: "ANCHOR",
  // },
  // {
  //   icon: BNB,
  //   name: "WBNB",
  //   address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  //   isAdd: false,
  //   isDel: false,
  //   decimals: 18,
  //   symbol: "WBNB",
  // },
  // {
  //   icon: BTC,
  //   name: "BTCB",
  //   address: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
  //   isAdd: false,
  //   isDel: false,
  //   decimals: 18,
  //   symbol: "BTCB",
  // },
  // {
  //   icon: ETH,
  //   name: "B-ETH",
  //   address: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
  //   isAdd: false,
  //   isDel: false,
  //   decimals: 18,
  //   symbol: "ETH",
  // },
  // {
  //   icon: CAKE,
  //   name: "CAKE",
  //   address: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
  //   isAdd: false,
  //   isDel: false,
  //   decimals: 18,
  //   symbol: "CAKE",
  // },
];

// ==============================

// WETH: {
//   name: 'wrapped ether token',
//   dec: 18,
//   sym: 'WETH',
//   icon: Eth,
//   bal: '',
//   isAdded: !0,
//   isDeleted: !1,
//   addr: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
// },
// TUR: {
//   name: 'tur token',
//   dec: 18,
//   sym: 'TUR',
//   icon: Gen,
//   bal: '',
//   isAdded: !0,
//   isDeleted: !1,
//   addr: '0xEe8F3Df788B0357d35D66F9023626f99f29a8351',
// },
// STEEP: {
//   name: 'steep labs token',
//   dec: 18,
//   sym: 'STEEP',
//   icon: Gen,
//   bal: '',
//   isAdded: !0,
//   isDeleted: !1,
//   addr: '0x8605c0c5E361dd897A5526558C48E7ff0D51353c',
// },
// SAITAMA: {
//   name: 'saitama token',
//   dec: 18,
//   sym: 'SAITAMA',
//   icon: Saitama,
//   bal: '',
//   isAdded: !0,
//   isDeleted: !1,
//   addr: '0x352E956eB0247792842ABD234d3f7425BBf544c2',
// }
// }
// ********* BSC_MAIN_NET Contract Address **********
export const MAIN_CONTRACT_LIST = {
  router: {
    address: "0x15834a53b3a53eac8e1b961cc02b8a9ea7fefd05",
    Block: 15210794,
    abi: routerABI,
  },
  panCakeRouter: {
    address: "0xd41dc8c0942669c498a270393d37201e82f4fa5c",
    blockNumber: 15210794,
    abi: routerABI,
  },
  factory: {
    address: "0xa4C8a3e0b49F966F1B241Ca350C5121230988310",
    blockNumber: 6430279,
    abi: factoryABI,
  },
  pair: {
    address: "",
    blockNumber: 0,
    abi: pairABI,
  }
};

// ******** BSC_TEST_NET Token List **********
// export const TOKEN_LIST = [
//   {
//     icon: BNB,
//     name: "BNB",
//     address: "BNB",
//     isAdd: false,
//     isDel: false,
//     decimals: 18,
//     symbol: "BNB",
//   },
//   {
//   {
//     icon: BUSD,
//     name: "BUSD",
//     address: "0x781F761139BB3B776DB8fD73DA5524E8eE458a97",
//     isAdd: false,
//     isDel: false,
//     decimals: 18,
//     symbol: "BUSD",
//   },
//   {
//     icon: ETH,
//     name: "ETH",
//     address: "0xe76833b8880B33adeC6d996B753E461A251bFd4e",
//     isAdd: false,
//     isDel: false,
//     decimals: 18,
//     symbol: "ETH",
//   },
//   {
//     icon: ADA,
//     name: "Cardano",
//     address: "0x8c033367885a452254b0FD4B8B4BBb0552D9Cd63",
//     isAdd: false,
//     isDel: false,
//     decimals: 18,
//     symbol: "ADA",
//   },
//   {
//     icon: defaultImg,
//     name: "Matic Token",
//     address: "0x589Ffc4669b1126364c472dd7E954C404F6649b0",
//     isAdd: false,
//     isDel: false,
//     decimals: 18,
//     symbol: "MATIC",
//   },
//   {
//     icon: defaultImg,
//     name: "MDex",
//     address: "0xc0ABFCD5a3090728939E74A036F9c82b4B261796",
//     isAdd: false,
//     isDel: false,
//     decimals: 18,
//     symbol: "MDX",
//   },
// ];

// ********* BSC_TestNet Contract Address **********
// export const MAIN_CONTRACT_LIST = {
//   router: {
//     address: "0x7489714061Fdf3B194D7151174817a3b2A1a918d",
//     blockNumber: 6810080,
//     abi: routerABI,
//   },
//   factory: {
//     address: "0x33c86EBefA8910Aa85Fa09AC09714Caee54a7AA6",
//     blockNumber: 6809737,
//     abi: factoryABI,
//   },
//   pair: {
//     address: "",
//     blockNumber: 0,
//     abi: pairABI,
//   }
// };

// ******* Extra tokens just for icons *********
// {
//   icon: defaultImg,
//   name: "Dai Token",
//   address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
//   isAdd: false,
//   isDel: false,
//   decimals: 18,
//   symbol: "DAI",
// },
// {
//   icon: BTC,
//   name: "BTCB Token",
//   address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
//   isAdd: false,
//   isDel: false,
//   decimals: 18,
//   symbol: "BTCB",
// },
// {
//   icon: USDT,
//   name: "Tether USD",
//   address: "0x55d398326f99059fF775485246999027B3197955",
//   isAdd: false,
//   isDel: false,
//   decimals: 18,
//   symbol: "USDT",
// },
// {
//   icon: POLKADOT,
//   name: "Polkadot Token",
//   address: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
//   isAdd: false,
//   isDel: false,
//   decimals: 18,
//   symbol: "DOT",
// },
// {
//   icon: TRON,
//   name: "TRON",
//   address: "0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B",
//   isAdd: false,
//   isDel: false,
//   decimals: 18,
//   symbol: "TRX",
// },
// {
//   icon: CAKE,
//   name: "PancakeSwap Token",
//   address: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
//   isAdd: false,
//   isDel: false,
//   decimals: 18,
//   symbol: "Cake",
// },
// {
//   icon: BNB,
//   name: "Wrapped BNB",
//   address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
//   isAdd: false,
//   isDel: false,
//   decimals: 18,
//   symbol: "WBNB",
// }
