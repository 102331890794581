import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Header.scss";
import { Link } from "react-router-dom";
import IconToggle from "../../assets/images/menu_toggle_icon.png";
import Iconmenu from "../../assets/images/wrap-menu.png";
import Button from "../Button/Button";
import ConnectWallet from "../ConnectWallet/ConnectWallet";
import ProfileModal from "../ProfileModal/ProfileModal";
import { login, logout, versionManager } from "../../redux/actions";
import { ContractServices } from "../../services/ContractServices";
import config from "dotenv";
import { WALLET_TYPE } from "../../constant";
import { useHistory } from "react-router";

const WEB_VER = process.env.WEB_VER;

const Header = (props) => {
  const dispatch = useDispatch();
  const isUserConnected = useSelector((state) => state.persist.isUserConnected);
  const walletType = useSelector((state) => state.persist.walletType);
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("Trade");
  const history = useHistory();

  useEffect(() => {
    const init = async () => {
      await dispatch(versionManager());
      if (walletType) {
        console.log(
          "[tur461] in useEffect header.js, wallet type changed to:",
          walletType
        );
        await ContractServices.setWalletType(walletType);
      } else {
        dispatch(logout());
      }
    };
    init();
    addListeners();
  }, []);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const connectCall = () => {
    isUserConnected ? setShow(!show) : setShow(true);
  };
  const addListeners = async () => {
    let address;
    if (walletType === WALLET_TYPE.META_MASK) {
      address = await ContractServices.isMetamaskInstalled("");
    }
    if (walletType === WALLET_TYPE.BSC) {
      address = await ContractServices.isBinanceChainInstalled();
    }

    ContractServices.walletWindowListener();
    if (address) {
      window.ethereum.on("accountsChanged", function (accounts) {
        const account = accounts[0];
        dispatch(login({ account, walletType }));
        window.location.reload();
      });
    }
  };
  const logoutCall = () => {
    dispatch(logout());
    setShow(false);
  };
  const headerOptions = {
    Trade: "/trade/exchange",
    Liquidity: "/trade/liquidity",
    Bridge: "/home",
    Earn: "/home",
    Blog: "home",
    Docs: "/home",
  };
  const headerMenus = ["Trade", "Liquidity", "Bridge", "Earn", "Blog", "Docs"];

  const redirectMenu = (menu) => {
    history.push(headerOptions[menu]);
    setActiveTab(menu);
  };
  // piyush, show me where you select wallet?
  return (
    <div className={`header_style ${props.className}`}>
      <div className="header_left_style">
        <a href="https://swap.vozcoin.io/" className="header_logo" />
      </div>
      <div className="header-menu">
        <ul>
          {headerMenus.map((menu, ind) => {
            return (
              <li
                key={ind}
                onClick={() => redirectMenu(menu)}
                className={menu == activeTab ? "active" : ""}
              >
                {menu}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="header_right_style">
        <Button
          onClick={() => connectCall()}
          title={
            isUserConnected
              ? `${isUserConnected.substring(1, 6)}...${isUserConnected.substr(
                  isUserConnected.length - 4
                )}`
              : "Connect"
          }
        />
      </div>
      {isUserConnected === "" && (
        <ConnectWallet
          show={show}
          handleShow={handleShow}
          handleClose={handleClose}
        />
      )}
      {isUserConnected !== "" && (
        <ProfileModal
          show={show}
          handleClose={handleClose}
          logout={logoutCall}
        />
      )}
    </div>
  );
};

export default Header;
